import * as React from "react"
import {
    Box,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Link,
} from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { themeDark } from "core/src/ui/theme-curvy/theme"
import Image from "next/image"
import { Formik } from "formik"
import { BaseThemeColors } from "core/src/baseThemeColors"
import { Button } from "core/src/ui/theme-curvy/components/Button"
import { motion } from "framer-motion"
import {
    useRegisterInterestFormsAsPublicRegisterInterestPostMutation,
    CreatePublicFormRegisterInterest,
} from "store/src/services/publicApi"

interface RegisterYourInterestProps {
    children?: React.ReactNode
}

export const RegisterYourInterest: React.FunctionComponent<RegisterYourInterestProps> = ({
    children,
}) => {
    const [triggerRegisterInterest, promise] =
        useRegisterInterestFormsAsPublicRegisterInterestPostMutation()

    const [showThankyouMessage, setShowThankyouMessage] = React.useState<boolean>(false)

    const thankyouMessage = (
        <Box
            component={motion.div}
            layout="position"
            layoutId="register-interest-form"
            sx={{
                py: 12,
                backgroundColor: BaseThemeColors.blue.main,
            }}
        >
            <Container fixed>
                <Typography
                    variant="h3"
                    component="h2"
                    sx={{
                        textAlign: "center",
                        pb: 2,
                        color: BaseThemeColors.gold.main,
                    }}
                >
                    Thank you
                </Typography>
                <Typography
                    sx={{
                        textAlign: "center",
                        pb: 0,
                        color: BaseThemeColors.white.main,
                    }}
                >
                    We've added you to our list,
                    <br />
                    we'll be in touch soon!
                </Typography>
            </Container>
        </Box>
    )

    const form = (
        <Box
            component={motion.div}
            layout="position"
            layoutId="register-interest-form"
            sx={{
                py: 12,
                backgroundColor: BaseThemeColors.blue.main,
            }}
        >
            <Container fixed>
                <Typography
                    variant="h3"
                    component="h2"
                    sx={{
                        textAlign: "center",
                        pb: 2,
                        color: BaseThemeColors.gold.main,
                    }}
                >
                    Register your interest
                </Typography>
                <Typography
                    sx={{
                        textAlign: "center",
                        pb: 6,
                        color: BaseThemeColors.white.main,
                    }}
                >
                    Stay up to date with Plus
                </Typography>
                <Formik
                    initialValues={{
                        name: "",
                        email: "",
                        accepted_terms: false,
                    }}
                    validate={(values) => {
                        if (values.accepted_terms === false) {
                            return {
                                accepted_terms: "Must be accepted",
                            }
                        }
                        return {}
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        triggerRegisterInterest({
                            createPublicFormRegisterInterest: {
                                name: values.name,
                                email: values.email,
                            } as CreatePublicFormRegisterInterest,
                        })
                            .unwrap()
                            .then(() => {
                                setShowThankyouMessage(true)
                            })
                            .finally(() => {
                                setSubmitting(false)
                            })
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: { xs: "wrap", lg: "nowrap" },
                                    justifyContent: "center",
                                    maxWidth: "700px",

                                    mx: "auto",
                                    color: "#fff",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: { xs: "100%", lg: "50%" },
                                        pb: 2,
                                        px: { lg: 1 },
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        label="Your Name"
                                        helperText="Your full name"
                                        required
                                        name="name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                        disabled={isSubmitting}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        width: { xs: "100%", lg: "50%" },
                                        pb: 2,
                                        px: { lg: 1 },
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        label="School / Work Email"
                                        type="email"
                                        helperText="Your school or work email"
                                        required
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        disabled={isSubmitting}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: { xs: "flex-end", sm: "center" },
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={values.accepted_terms}
                                            name="accepted_terms"
                                            onChange={handleChange}
                                            required
                                        />
                                    }
                                    sx={{ ml: { xs: 2, sm: undefined } }}
                                    label={
                                        <Typography sx={{ color: BaseThemeColors.white.main }}>
                                            Please keep me updated by email
                                        </Typography>
                                    }
                                    disabled={isSubmitting}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: { xs: "wrap", lg: "nowrap" },
                                    justifyContent: "center",
                                    pt: 4,
                                }}
                            >
                                <Button variant="contained" color="green" type="submit">
                                    Register Interest
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Container>
        </Box>
    )

    return (
        <Box
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.6 }}
        >
            <ThemeProvider theme={themeDark}>
                {showThankyouMessage ? thankyouMessage : form}
            </ThemeProvider>
        </Box>
    )
}
