import * as React from "react"
import { Alert, Box, TextField, Typography } from "@mui/material"
import { Formik } from "formik"
import { Button } from "core/src/ui/theme-curvy/components/Button"
import { useDispatch } from "react-redux"
import {
    useLoginAuthTokenByEmailPostMutation,
    authenticationApi,
} from "store/src/services/authenticationApi"
import { setAuthenticate } from "store/src/authenticationSlice"
import { useRouter } from "next/router"

import { ThemeProvider } from "@mui/material/styles"
import { themeLight } from "core/src/ui/theme-curvy/theme"

interface LoginFormProps {
    mobile?: boolean
}

export const LoginForm: React.FunctionComponent<LoginFormProps> = ({ mobile = false }) => {
    const router = useRouter()
    const dispatch = useDispatch()
    const [login, result] = useLoginAuthTokenByEmailPostMutation()

    const [serverError, setServerError] = React.useState<string | null>(null)

    return (
        <ThemeProvider theme={themeLight}>
            <Box
                sx={{
                    ...(mobile
                        ? {
                              display: {
                                  xs: "block",
                                  lg: "none",
                              },
                          }
                        : {}),
                    width: "100%",
                }}
            >
                <Formik
                    initialValues={{
                        username: "",
                        password: "",
                    }}
                    validate={(values) => {
                        return {}
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setServerError(null)

                        login({
                            bodyLoginAuthTokenByEmailPost: values,
                        })
                            .unwrap()
                            .then((authenticate) => {
                                dispatch(setAuthenticate({ authenticate }))
                                router.push(
                                    authenticate.redirect.url +
                                        (process.env.NODE_ENV !== "production"
                                            ? "?" +
                                              new URLSearchParams({
                                                  auth: JSON.stringify(authenticate),
                                              }).toString()
                                            : "")
                                )
                            })
                            .catch((error) => {
                                dispatch(setAuthenticate({ authenticate: null }))
                                setServerError(error?.data?.detail.toString() || "Server error")
                            })
                            .finally(() => {
                                setSubmitting(false)
                            })
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box sx={{ pb: 2 }}>
                                <Typography variant="h5" gutterBottom>
                                    Login...
                                </Typography>
                            </Box>
                            <Box sx={{ pb: 2 }}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    label="Username / Email"
                                    error={!!errors?.username}
                                    helperText={!!errors?.username ? errors.username : undefined}
                                    sx={{ width: "100%" }}
                                    name="username"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.username}
                                    disabled={isSubmitting}
                                    required
                                />
                            </Box>
                            <Box sx={{ pb: 2 }}>
                                <TextField
                                    type="password"
                                    variant="outlined"
                                    label="Password"
                                    error={!!errors?.password}
                                    helperText={!!errors?.password ? errors.password : undefined}
                                    sx={{ width: "100%" }}
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    disabled={isSubmitting}
                                    required
                                />
                            </Box>
                            <Box sx={{ pt: 2, textAlign: "right" }}>
                                <Button
                                    variant="contained"
                                    color="green"
                                    size="large"
                                    containerStyle={{ display: "block" }}
                                    sx={{ py: 1.5 }}
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    Login
                                </Button>
                            </Box>
                            {serverError && (
                                <Alert severity="warning" sx={{ mt: 3 }}>
                                    <Typography variant="body2">{serverError}</Typography>
                                </Alert>
                            )}
                        </form>
                    )}
                </Formik>
            </Box>
        </ThemeProvider>
    )
}
