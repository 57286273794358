import * as React from "react"
import { Box, Container, Link as LinkMui, TextField, Typography } from "@mui/material"

import NextLink from "next/link"
import { BaseThemeColors } from "core/src/baseThemeColors"
import { Button } from "core/src/ui/theme-curvy/components/Button"
import { headerMaxHeightPx } from "./Header"
import { motion } from "framer-motion"

import { LoginForm } from "./forms/LoginForm"
import Image, { StaticImageData } from "next/image"
import BackgroundSvg from "../media/images/background.svg"

interface HeroHomepageProps {
    children?: React.ReactNode
    title: string
    subtitle: string
    introduction: React.ReactNode | string
    aboutUrl?: string
}

export const HeroHomepage: React.FunctionComponent<HeroHomepageProps> = ({
    children,
    title,
    subtitle,
    introduction,
    aboutUrl,
}) => {
    return (
        <Box
            component={motion.div}
            layoutId="hero"
            transition={{ duration: 0.4, type: "spring" }}
            animate={{ scaleY: 1 }}
            sx={{
                position: "relative",
                transformOrigin: "top",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pt: {
                    xs: "8rem",
                    lg: headerMaxHeightPx + "px",
                },
                pb: {
                    xs: "8rem",
                    lg: "0",
                },
                backgroundColor: BaseThemeColors.white.main,
                minHeight: {
                    xs: "100vh",
                    lg: "auto",
                },
            }}
        >
            <Box
                component={motion.div}
                sx={{
                    display: { xs: "none", lg: "block" },
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    opacity: 0.15,
                    transform: "rotate(180deg)",
                }}
                transition={{ duration: 0.4, delay: 0.3 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.2 }}
            >
                <Image
                    src={BackgroundSvg}
                    objectFit="contain"
                    layout="fill"
                    objectPosition="center"
                />
            </Box>

            <Container
                fixed
                sx={{
                    position: "relative",
                    display: "flex",
                    flex: 1,
                }}
            >
                <Box
                    component={motion.div}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.4 }}
                    sx={{
                        width: {
                            xs: "100%",
                            lg: "60%",
                        },
                        pt: {
                            xs: headerMaxHeightPx * 0.25 + "px",
                            sm: headerMaxHeightPx * 1 + "px",
                            lg: headerMaxHeightPx * 1.5 + "px",
                        },
                        pb: {
                            xs: headerMaxHeightPx * 0.5 + "px",
                            sm: headerMaxHeightPx * 1 + "px",
                            lg: headerMaxHeightPx * 1.5 + "px",
                        },
                        textAlign: {
                            xs: "center",
                            lg: "left",
                        },
                    }}
                >
                    <Typography variant="h5" sx={{ color: BaseThemeColors.blue.main }}>
                        {subtitle}
                    </Typography>
                    <Typography variant="h2" component="h1">
                        {title}
                    </Typography>
                    <Typography
                        component="div"
                        sx={{
                            color: BaseThemeColors.grey.main,
                            maxWidth: { lg: "500px" },
                        }}
                    >
                        {children ? children : introduction}
                    </Typography>
                    {aboutUrl && (
                        <Box
                            sx={{
                                display: { sm: "flex", lg: "block" },
                                flexDirection: "column",
                                alignItems: "center",
                                pt: 4,
                                width: "100%",
                                maxWidth: {
                                    xs: "300px",
                                    sm: "450px",
                                    md: "600px",
                                    lg: "250px",
                                },
                                mx: { xs: "auto", lg: 0 },
                            }}
                        >
                            <LoginForm mobile={true} />

                            <NextLink passHref href="/about-us">
                                <Button
                                    variant="outlined"
                                    color="grey"
                                    size="large"
                                    containerStyle={{ display: "block" }}
                                    sx={{
                                        py: {
                                            xs: 1.5,
                                            lg: 0.5,
                                        },
                                        mt: "1.25rem",
                                    }}
                                >
                                    Learn More
                                </Button>
                            </NextLink>
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        display: {
                            xs: "none",
                            lg: "block",
                        },
                        width: {
                            xs: "100%",
                            lg: "40%",
                        },
                        height: "100%",
                        flex: 1,
                    }}
                >
                    <Box
                        sx={{
                            position: {
                                xs: "relative",
                                lg: "absolute",
                            },
                            bottom: 0,
                            right: "1rem",
                            width: {
                                xs: "100%",
                                lg: "40%",
                            },
                            height: {
                                xs: "auto",
                                lg: "82.5%",
                            },
                        }}
                    >
                        <motion.div
                            initial={{ y: 0, opacity: 0, scale: 0 }}
                            animate={{ y: 0, opacity: 1, scale: 1 }}
                            transition={{
                                duration: 0.8,
                                type: "spring",
                            }}
                            style={{ height: "100%", originY: 1 }}
                        >
                            <Box
                                sx={{
                                    height: "100%",
                                    backgroundColor: "#fff",
                                    borderRadius: "1rem 1rem 0rem 0rem",
                                    p: 4,
                                }}
                            >
                                <LoginForm />
                            </Box>
                        </motion.div>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
