import * as React from "react"
import Head from "next/head"
import { Box, Link as LinkMui, Typography, Icon, CircularProgress } from "@mui/material"
import NextLink from "next/link"
import { Button } from "core/src/ui/theme-curvy/components/Button"
import { TextLeftImageRight } from "../src/components/TextLeftImageRight"
import { Header } from "../src/components/Header"
import { HeroHomepage } from "../src/components/HeroHomepage"
import { SchoolsOnTrial } from "../src/components/SchoolsOnTrial"
import { RegisterYourInterest } from "../src/components/RegisterYourInterest"
import { Footer } from "../src/components/Footer"
import { BaseThemeColors } from "core/src/baseThemeColors"
import Mockup from "../src/media/images/homepage-mockup-teacher-with-student-dashboard.jpg"
import StatOne from "../src/media/images/stat-1.jpeg"
import StatTwo from "../src/media/images/stat-2.jpg"
import { TextAndContent } from "../src/components/TextAndContent"

export default function Web() {
    const personStyle: any = {
        transform: {
            xs: "scale(2)",
            lg: "scale(3.5)",
        },
        marginRight: {
            xs: 4,
            lg: 8,
        },
        marginBottom: {
            xs: 4,
            lg: 7,
        },
    }

    return (
        <>
            <Head>
                <title>Plus | Home</title>
            </Head>
            <Header hasBigLogo={true} />
            <HeroHomepage
                subtitle="Supporting teachers in"
                title="Delivering Health & Wellbeing for Schools"
                introduction={
                    <Typography>A wellbeing platform for students, teachers & schools</Typography>
                }
                aboutUrl="/about-us"
            />
            <TextLeftImageRight
                alignCenter
                title={<>Welcome to Plus</>}
                imageUrl={Mockup}
                gutterTop
            >
                <Typography gutterBottom>
                    Plus-ed is a digital platform focused on supporting teachers in delivering
                    health & wellbeing in schools. We know the power of creating positive habits
                    from a young age in children and the effect this can have on their future. We
                    combine physical health, emotional health & communication in seamless journal
                    which provides actionable data, insights and resources to teachers and schools
                    enabling a proactive approach in managing children's health & wellbeing - saving
                    teachers time and schools money.
                </Typography>
                <Typography gutterBottom>
                    By supporting teachers, we give every child the best possible chance of reaching
                    their potential.
                </Typography>
                <Box sx={{ pt: 3 }}>
                    <NextLink passHref href="/about-us">
                        <Button variant="contained" color="blue">
                            Learn More
                        </Button>
                    </NextLink>
                </Box>
            </TextLeftImageRight>

            <TextAndContent
                flip={true}
                alignCenter
                title={
                    <>
                        <Box sx={{ color: BaseThemeColors.blue.main, display: "inline" }}>
                            <b>6 children</b>
                        </Box>{" "}
                        in a class of 30 between the ages of 6 to 16 have a probable mental health
                        condition.
                        <br />
                        <br />
                        <Box sx={{ fontSize: "16px" }}>– NHS 2021</Box>
                    </>
                }
                imageUrl={StatOne}
                contentChildren={
                    <>
                        {Array.from({ length: 30 }, (_, i) => (
                            <>
                                <Icon
                                    sx={{
                                        ...personStyle,
                                        ...(i < 6
                                            ? {
                                                  color: BaseThemeColors.orange.main,
                                              }
                                            : {
                                                  color: BaseThemeColors.green.main,
                                              }),
                                        ...((i + 1) % 5 === 0 && i !== 0
                                            ? {
                                                  marginRight: 0,
                                              }
                                            : {}),
                                    }}
                                >
                                    accessibility_new
                                </Icon>
                                {(i + 1) % 5 === 0 && i !== 0 && <br />}
                            </>
                        ))}
                    </>
                }
                gutterTop
            ></TextAndContent>

            <TextAndContent
                alignCenter
                title={
                    <>
                        <Box sx={{ color: BaseThemeColors.blue.main, display: "inline" }}>
                            <b>50% of all mental health conditions</b>
                        </Box>{" "}
                        are established by time a child is aged 14.
                        <br />
                        <br />
                        <Box sx={{ fontSize: "16px" }}>– World Health Organisation 2012</Box>
                    </>
                }
                contentChildren={
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            height: "400px",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: "40px",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    ml: {
                                        xs: 0,
                                        lg: 8,
                                    },
                                }}
                            >
                                <CircularProgress
                                    variant="determinate"
                                    value={50}
                                    size={350}
                                    thickness={5}
                                    color={"warning"}
                                />
                            </Box>
                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: "40px",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    ml: {
                                        xs: 0,
                                        lg: 8,
                                    },
                                }}
                            >
                                <CircularProgress
                                    variant="determinate"
                                    value={-50}
                                    size={350}
                                    thickness={5}
                                    color={"secondary"}
                                />
                            </Box>
                            <Typography
                                sx={{
                                    position: "absolute",
                                    bottom: "45%",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    color: BaseThemeColors.orange.main,
                                    fontSize: "64px",
                                    fontWeight: 700,
                                    ml: {
                                        xs: 0,
                                        lg: 8,
                                    },
                                }}
                            >
                                50%
                            </Typography>
                        </Box>
                    </Box>
                }
                imageUrl={StatTwo}
                gutterTop
            ></TextAndContent>

            <SchoolsOnTrial />
            <RegisterYourInterest />
            <Footer />
        </>
    )
}
