import * as React from "react"
import { Button as ButtonMaterial, Box, lighten, darken, SxProps, Theme } from "@mui/material"
import { ButtonProps, ButtonPropsVariantOverrides } from "@mui/material/Button"
import { BaseThemeColors } from "../../../baseThemeColors"

interface ExtendedButtonProps {
    color?:
        | "inherit"
        | "primary"
        | "secondary"
        | "success"
        | "error"
        | "info"
        | "warning"
        | "black"
        | "green"
        | "orange"
        | "blue"
        | "blueDark"
        | "lilac"
        | "gold"
        | "white"
        | "grey"
    containerStyle?: SxProps
}

export const Button = ({
    children,
    sx,
    color,
    containerStyle,
    ...props
}: ExtendedButtonProps & Omit<ButtonProps, keyof ExtendedButtonProps>) => {
    const colorName = color ? color : "secondary"
    const isColorMaterial =
        ["inherit", "primary", "secondary", "success", "error", "info", "warning"].findIndex(
            (color: string) => color === colorName
        ) > -1

    return (
        <Box
            sx={{
                display: "inline-block",
                position: "relative",
                zIndex: 1,
                mb: 1,
                ...(containerStyle ? containerStyle : {}),
            }}
        >
            <ButtonMaterial
                disableElevation
                sx={(theme: Theme) => ({
                    minWidth: "100%",
                    px: 6,
                    transform: "translateY(-4px)",
                    ...(props?.variant && props.variant === "contained"
                        ? {
                              backgroundColor: isColorMaterial
                                  ? theme.palette[colorName].main
                                  : BaseThemeColors[colorName].main,
                              "&:hover": {
                                  backgroundColor: isColorMaterial
                                      ? lighten(theme.palette[colorName].main, 0.1)
                                      : lighten(BaseThemeColors[colorName].main, 0.1),
                              },
                          }
                        : {}),
                    ...(props?.variant &&
                    (props.variant === "contained" || props.variant === "outlined")
                        ? {
                              zIndex: 1,
                              "&:focus:active": {
                                  transform: "translateY(0px)",
                              },
                              "&:focus:active + div": {
                                  transform: "scaleY(0)",
                              },
                              "&:disabled": {
                                  //   color: "rgba(255,255,255,0.4)",
                              },
                              "&.Mui-disabled + div": {
                                  filter: "grayscale(0.8) brightness(1.2)",
                              },
                          }
                        : {}),
                    ...(props?.variant && props.variant === "outlined"
                        ? {
                              borderColor: isColorMaterial
                                  ? theme.palette[colorName].main
                                  : BaseThemeColors[colorName].main,
                              color: isColorMaterial
                                  ? theme.palette[colorName].main
                                  : BaseThemeColors[colorName].main,
                              "&:hover, &:focus": {
                                  borderColor: isColorMaterial
                                      ? darken(theme.palette[colorName].main, 0.1)
                                      : darken(BaseThemeColors[colorName].main, 0.1),
                                  color: isColorMaterial
                                      ? darken(theme.palette[colorName].main, 0.1)
                                      : darken(BaseThemeColors[colorName].main, 0.1),
                              },
                          }
                        : {}),
                    ...(props?.variant && props.variant === "text"
                        ? {
                              color: isColorMaterial
                                  ? theme.palette[colorName].main
                                  : BaseThemeColors[colorName].main,
                          }
                        : {}),
                    ...sx,
                })}
                {...(isColorMaterial ? { color } : {})}
                {...props}
            >
                {children}
            </ButtonMaterial>
            {props?.variant && (props.variant === "contained" || props.variant === "outlined") && (
                <Box
                    sx={(theme) => {
                        return {
                            position: "absolute",
                            display: {
                                xs: "block",
                                lg: "flex",
                            },
                            bottom: 0,
                            left: "0px",
                            width: "calc(100% - 0px)",
                            height: "calc(100% + 4px)",
                            borderRadius: "14px",

                            transform: "scaleY(1)",
                            zIndex: -1,
                            borderBottom: "4px solid",
                            borderColor: isColorMaterial
                                ? darken(theme.palette[colorName].main, 0.2)
                                : darken(BaseThemeColors[colorName].main, 0.1),
                            transition: "border-color 0.2s ease",
                        }
                    }}
                ></Box>
            )}
        </Box>
    )
}
